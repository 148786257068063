@-webkit-keyframes error500animation-data-v-4cf39caa {
0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
20% {
    -webkit-transform: rotateZ(-10deg);
            transform: rotateZ(-10deg);
}
40% {
    -webkit-transform: rotateZ(5deg);
            transform: rotateZ(5deg);
}
60% {
    -webkit-transform: rotateZ(-5deg);
            transform: rotateZ(-5deg);
}
80% {
    -webkit-transform: rotateZ(10deg);
            transform: rotateZ(10deg);
}
100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
}
@keyframes error500animation-data-v-4cf39caa {
0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
20% {
    -webkit-transform: rotateZ(-10deg);
            transform: rotateZ(-10deg);
}
40% {
    -webkit-transform: rotateZ(5deg);
            transform: rotateZ(5deg);
}
60% {
    -webkit-transform: rotateZ(-5deg);
            transform: rotateZ(-5deg);
}
80% {
    -webkit-transform: rotateZ(10deg);
            transform: rotateZ(10deg);
}
100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
}
.error500-body-con[data-v-4cf39caa] {
  width: 700px;
  height: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.error500-body-con-title[data-v-4cf39caa] {
    text-align: center;
    font-size: 240px;
    font-weight: 700;
    color: #2d8cf0;
    height: 260px;
    line-height: 260px;
    margin-top: 40px;
}
.error500-body-con-title .error500-0-span[data-v-4cf39caa] {
      display: inline-block;
      position: relative;
      width: 170px;
      height: 170px;
      border-radius: 50%;
      border: 20px solid #ed3f14;
      color: #ed3f14;
      margin-right: 10px;
}
.error500-body-con-title .error500-0-span i[data-v-4cf39caa] {
        display: inline-block;
        font-size: 120px !important;
        position: absolute;
        bottom: -43px;
        left: 20px;
        -webkit-transform-origin: center bottom;
                transform-origin: center bottom;
        -webkit-animation: error500animation-data-v-4cf39caa 3s ease 0s infinite alternate;
                animation: error500animation-data-v-4cf39caa 3s ease 0s infinite alternate;
}
.error500-body-con-message[data-v-4cf39caa] {
    display: block;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 4px;
    color: #dddde2;
}
.error500-btn-con[data-v-4cf39caa] {
  text-align: center;
  padding: 20px 0;
  margin-bottom: 40px;
}
