@charset "UTF-8";
[data-v-6bc2799e] .el-form-item {
  margin-top: 50px;
  border: 1px solid #cccccc;
  border-radius: 4px;
}
[data-v-6bc2799e] .el-form-item:hover {
  border: 1px solid #3b94ff;
}
.login-box .login-tab[data-v-6bc2799e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  color: #999;
  margin: 40px 80px;
}
.login-box .login-tab .tab-item[data-v-6bc2799e] {
    cursor: pointer;
}
.login-box .login-tab .tab-line[data-v-6bc2799e] {
    width: 1px;
    background-color: #d8d8d8;
    height: 18px;
}
.login-box .login-tab .is-active[data-v-6bc2799e] {
    color: #3b94ff;
}
.login-box .login-form[data-v-6bc2799e] {
  margin: 10px 0;
}
.login-box .login-form i[data-v-6bc2799e] {
    color: #999;
}
.login-box .login-form[data-v-6bc2799e] .el-form-item__content {
    width: 100%;
}
.login-box .login-form[data-v-6bc2799e] .el-form-item {
    margin-bottom: 12px;
}
.login-box .login-form[data-v-6bc2799e] .el-input input {
    text-indent: 5px;
    border: none;
    border-radius: 0;
    background: transparent;
    color: #333;
}
.login-box .login-form[data-v-6bc2799e] .el-input i {
    font-size: 16px !important;
    cursor: pointer;
}
.login-box .login-form[data-v-6bc2799e] .el-input__prefix {
    left: 10px;
}
.login-box .login-form[data-v-6bc2799e] .el-input__prefix img {
      vertical-align: -3px;
}
.login-box .login-form[data-v-6bc2799e] .el-form-item__error {
    color: #e20000;
    font-size: 14px;
    height: 50px;
    line-height: 50px;
    padding-top: 0;
}
.login-box .login-form[data-v-6bc2799e] .el-input--small .el-input__inner {
    height: auto;
    line-height: inherit;
}
.login-box .login-form .suffix-icon-password[data-v-6bc2799e] {
    width: 16px;
    cursor: pointer;
    margin-right: 6px;
    vertical-align: -3px;
}
.login-box .login-button–primary[data-v-6bc2799e] {
  margin-top: 50px;
  color: #fff;
  cursor: pointer;
  height: 44px;
  text-align: center;
  font-size: 16px;
  line-height: 44px;
  background: #3b94ff;
  border-radius: 4px;
  background-size: 100% 100%;
}
.login-box .login-button–primary[data-v-6bc2799e]:hover {
  background: #32b4f9;
}
.login-box .remember-password[data-v-6bc2799e] {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.login-box .remember-password .find-password[data-v-6bc2799e] {
    color: #3b94ff;
    font-size: 14px;
    cursor: pointer;
}
.login-box .go-back[data-v-6bc2799e] {
  margin-top: 20px;
  text-align: center;
}
.login-box .go-back span[data-v-6bc2799e] {
    color: #3b94ff;
    font-size: 14px;
    cursor: pointer;
}
.login-box .suffix-text[data-v-6bc2799e] {
  font-size: 14px;
  margin-right: 10px;
  cursor: pointer;
  color: #3b94ff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.login-box .suffix-text .suffix-line[data-v-6bc2799e] {
    margin-right: 14px;
    width: 1px;
    height: 18px;
    background-color: #3b94ff;
    vertical-align: middle;
}
.login-box .suffix-text .suffix-line.display-line[data-v-6bc2799e] {
      background-color: #ccc;
}
.login-box .suffix-text .msg-text[data-v-6bc2799e] {
    width: 80px;
}
.login-box .suffix-text .msg-text.display[data-v-6bc2799e] {
      color: #ccc;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
.login-box .login-tab[data-v-6bc2799e] {
    margin: 20px 40px;
}
.login-box .login-button–primary[data-v-6bc2799e] {
    margin-top: 30px;
}
.login-box .el-form-item[data-v-6bc2799e] {
    margin-top: 30px;
}
.login-box .drag[data-v-6bc2799e] {
    margin-top: 30px;
}
}
