@-webkit-keyframes error404animation-data-v-1845bf6d {
0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
20% {
    -webkit-transform: rotateZ(-60deg);
            transform: rotateZ(-60deg);
}
40% {
    -webkit-transform: rotateZ(-10deg);
            transform: rotateZ(-10deg);
}
60% {
    -webkit-transform: rotateZ(50deg);
            transform: rotateZ(50deg);
}
80% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}
100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
}
@keyframes error404animation-data-v-1845bf6d {
0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
20% {
    -webkit-transform: rotateZ(-60deg);
            transform: rotateZ(-60deg);
}
40% {
    -webkit-transform: rotateZ(-10deg);
            transform: rotateZ(-10deg);
}
60% {
    -webkit-transform: rotateZ(50deg);
            transform: rotateZ(50deg);
}
80% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}
100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
}
.error404-body-con[data-v-1845bf6d] {
  width: 700px;
  height: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.error404-body-con-title[data-v-1845bf6d] {
    text-align: center;
    font-size: 240px;
    font-weight: 700;
    color: #2d8cf0;
    height: 260px;
    line-height: 260px;
    margin-top: 40px;
}
.error404-body-con-title span[data-v-1845bf6d] {
      display: inline-block;
      color: #19be6b;
      font-size: 230px;
      -webkit-animation: error404animation-data-v-1845bf6d 3s ease 0s infinite alternate;
              animation: error404animation-data-v-1845bf6d 3s ease 0s infinite alternate;
}
.error404-body-con-message[data-v-1845bf6d] {
    display: block;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 12px;
    color: #dddde2;
}
.error404-btn-con[data-v-1845bf6d] {
  text-align: center;
  padding: 20px 0;
  margin-bottom: 40px;
}
