[data-v-8e8f3fba] .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.drug-name[data-v-8e8f3fba] {
  color: #fd9c12;
  font-weight: bold;
}
.line-row[data-v-8e8f3fba] {
  height: 1px;
  background-color: #e0e0e0;
  margin: 6px 0;
}
[data-v-8e8f3fba] .el-backtop {
  width: 48px;
  height: 70px;
  font-size: 12px;
  border-radius: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.back-top[data-v-8e8f3fba] {
  text-align: center;
}
.back-top .el-image[data-v-8e8f3fba] {
    background-color: #fff;
}
.aide-check-item[data-subtitle][data-v-8e8f3fba]:before {
  content: attr(data-subtitle);
  display: inline-block;
  color: #333;
  font-size: 14px;
  font-weight: bold;
  background-color: #fff;
  position: relative;
  z-index: 1;
  line-height: 24px;
  margin: 8px 0;
}
.aide-check-item[data-subtitle][data-v-8e8f3fba] {
  position: relative;
}
.no-margin-top[data-subtitle][data-v-8e8f3fba]:before {
  margin-top: 0;
}
.aide-check-item[data-v-8e8f3fba] {
  font-size: 14px;
  line-height: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.aide-check-item.no-border[data-v-8e8f3fba] {
    border: none;
}
.aide-check-item .title-left[data-v-8e8f3fba] {
    text-align: left;
}
.aide-check-item .title-right[data-v-8e8f3fba] {
    text-align: right;
    float: right;
    padding-right: 6px;
}
.aide-home[data-v-8e8f3fba] {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #333333;
}
.aide-home .drug-use-method[data-v-8e8f3fba] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.aide-home .drug-use-method > div[data-v-8e8f3fba] {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
}
.aide-home .check-title[data-v-8e8f3fba] {
    margin: 10px 0;
}
.aide-home .send-box[data-v-8e8f3fba] {
    text-align: right;
    float: right;
}
.aide-home .send-box .check-click[data-v-8e8f3fba] {
      margin-left: 20px;
      color: #f07503;
      cursor: pointer;
      font-weight: bold;
}
.aide-home .check-box[data-v-8e8f3fba] {
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    padding: 12px;
}
.aide-home .unpass[data-v-8e8f3fba] {
    color: #e02020;
}
.aide-home .pass[data-v-8e8f3fba] {
    color: #66cc66;
}
.aide-home .c-error[data-v-8e8f3fba] {
    color: #e20000;
}
.aide-home .c-warning[data-v-8e8f3fba] {
    color: #fd9c12;
}
.aide-home .mgtb12[data-v-8e8f3fba] {
    margin-top: 6px;
    margin-bottom: 6px;
}
.aide-home .line-left[data-v-8e8f3fba] {
    text-align: right;
    font-weight: 600;
    height: 32px;
    line-height: 32px;
    padding-right: 16px;
    border-top: 1px solid #d8d8d8;
    border-right: 1px solid #d8d8d8;
}
.aide-home .line-right[data-v-8e8f3fba] {
    height: 32px;
    line-height: 18px;
    text-align: left;
    padding: 12px 12px 12px 18px;
    border-top: 1px solid #d8d8d8;
}
.aide-home .drug-usage-dosage[data-v-8e8f3fba] {
    overflow: hidden;
}
.aide-home .drug-usage-dosage .check-use-method-list[data-v-8e8f3fba] {
      margin-bottom: 18px;
}
.aide-home .drug-usage-dosage .check-use-method-list[data-v-8e8f3fba]:last-of-type {
        margin-bottom: 0px;
}
.aide-home .drug-usage-dosage .check-use-method-list .check-use-method .check-drug-name[data-v-8e8f3fba] {
        font-weight: bold;
}
.aide-home .drug-usage-dosage .check-operate[data-v-8e8f3fba] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: #3b94ff;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      height: 40px;
      cursor: pointer;
}
.aide-home .drug-usage-dosage .check-operate i[data-v-8e8f3fba] {
        margin-right: 12px;
}
.aide-home .check-item .new-title[data-v-8e8f3fba] {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    height: 40px;
    color: #e20000;
}
.aide-home .check-item .new-title .new-title-right[data-v-8e8f3fba] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.aide-home .check-item .new-title .new-title-right .el-image[data-v-8e8f3fba] {
        margin-top: 2px;
        margin-left: 10px;
}
.aide-home .aide-home-result[data-v-8e8f3fba] {
    text-align: left;
}
.aide-home .aide-home-result .aide-home-checking[data-v-8e8f3fba] {
      font-weight: 600;
}
.aide-home .aide-home-result .aide-home-checking .el-icon-loading[data-v-8e8f3fba] {
        margin-left: 10px;
}
.aide-home .aide-home-result .aide-home-done[data-v-8e8f3fba] {
      text-align: left;
}
.aide-home .aide-home-result .aide-home-done .result-done[data-v-8e8f3fba] {
        font-weight: 600;
}
.aide-home .aide-home-result .aide-home-done .result-top[data-v-8e8f3fba] {
        text-align: center;
        background-color: #ffe5e5;
        height: 132px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        border-radius: 8px;
}
.aide-home .aide-home-result .aide-home-done .result-top .result-check[data-v-8e8f3fba] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center;
}
.aide-home .aide-home-result .aide-home-done .result-top .result-check .result-logo[data-v-8e8f3fba] {
            text-align: center;
}
.aide-home .aide-home-result .aide-home-done .result-top .result-check .result-text[data-v-8e8f3fba] {
            font-size: 20px;
            font-weight: 600;
            margin-left: 10px;
}
.aide-home .aide-home-result .aide-home-done .result-top .button-box[data-v-8e8f3fba] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          margin-top: 20px;
}
.aide-home .aide-home-result .aide-home-done .result-top .button-box .update-use[data-v-8e8f3fba] {
            cursor: pointer;
            width: 132px;
            height: 36px;
            background: #f4faff;
            border: 1px solid #3b94ff;
            border-radius: 5px;
            font-size: 16px;
            color: #2d85ee;
            line-height: 36px;
}
.aide-home .aide-home-result .aide-home-done .result-top .button-box .cxjc[data-v-8e8f3fba] {
            cursor: pointer;
            width: 100px;
            height: 36px;
            line-height: 36px;
            background: #f4faff;
            border: 1px solid #3b94ff;
            border-radius: 5px;
            margin-left: 10px;
            font-size: 16px;
            color: #2d85ee;
}
.aide-home .aide-home-result .aide-home-done .result-list[data-v-8e8f3fba] {
        text-align: left;
}
.aide-home .aide-home-result .aide-home-done .result-list .result-reason[data-v-8e8f3fba] {
          color: #e02020;
}
.aide-home .aide-home-result .aide-home-done .result-list .result-warnig[data-v-8e8f3fba] {
          color: #fd9c12;
}
.aide-home .aide-home-check[data-v-8e8f3fba] {
    text-align: left;
}
.aide-home .aide-home-check .aide-title[data-v-8e8f3fba] {
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600;
      margin-bottom: 12px;
}
.aide-home .aide-home-check .aide-check-body .check-user .check-col[data-v-8e8f3fba],
    .aide-home .aide-home-check .aide-check-body .check-dignose .check-col[data-v-8e8f3fba] {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding-right: 12px;
      height: 24px;
      line-height: 24px;
}
.aide-home .aide-home-check .aide-check-body .check-user .check-left[data-v-8e8f3fba],
    .aide-home .aide-home-check .aide-check-body .check-dignose .check-left[data-v-8e8f3fba] {
      text-align: left;
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
}
.aide-home .aide-home-check .aide-check-body .check-user .check-right[data-v-8e8f3fba],
    .aide-home .aide-home-check .aide-check-body .check-dignose .check-right[data-v-8e8f3fba] {
      text-align: right;
}
.aide-home .aide-home-check .aide-check-body .advise-result-list[data-v-8e8f3fba] {
      line-height: 24px;
}
.aide-home .aide-home-check .aide-check-body .advise-result-list .usewenti[data-v-8e8f3fba] {
        line-height: 24px;
}
.aide-home .aide-home-check .aide-check-body .advise-result-list .jcFx[data-v-8e8f3fba] {
        text-align: right;
        color: #3b94ff;
        padding-right: 6px;
        cursor: pointer;
}
.aide-home .aide-home-check .aide-check-body .advise-result-list .wtFx[data-v-8e8f3fba] {
        text-align: right;
        color: #3b94ff;
        padding-right: 6px;
        cursor: pointer;
}
.aide-home .aide-home-drug[data-v-8e8f3fba] {
    margin-bottom: 20px;
    text-align: left;
}
.aide-home .aide-home-drug .aide-title[data-v-8e8f3fba] {
      font-size: 14px;
      color: #333333;
      letter-spacing: 0;
      font-weight: 600;
      margin-bottom: 12px;
}
.aide-home .aide-home-drug .aide-check-body[data-v-8e8f3fba] {
      padding: 0;
}
.aide-home .aide-home-drug .aide-check-body .drug-active[data-v-8e8f3fba] {
        padding-top: 12px;
        padding-left: 32px;
}
.aide-home .aide-home-drug .aide-check-body[data-v-8e8f3fba] .el-tabs__header {
        margin-top: 2px;
}
.aide-home .aide-home-drug .aide-check-body[data-v-8e8f3fba] .el-tabs__item {
        color: #333;
        font-weight: 400;
        height: 24px;
        line-height: 24px;
}
.aide-home .aide-home-drug .aide-check-body[data-v-8e8f3fba] .el-tabs__item.is-active {
        color: #3b94ff;
}
.aide-home .aide-home-drug .aide-check-body[data-v-8e8f3fba] .el-tabs__active-bar {
        background-color: #3b94ff;
}
.aide-home .aide-home-drug .aide-check-body[data-v-8e8f3fba] .el-tabs__nav-wrap::after {
        background: #fff;
}
.aide-home .aide-home-drug .aide-check-body .drug-list .drug-detail[data-v-8e8f3fba] {
        font-size: 12px;
}
.aide-home .aide-home-drug .aide-check-body .drug-list .drug-detail .drug-img[data-v-8e8f3fba] {
          text-align: right;
          padding: 16px;
}
.aide-home .aide-home-drug .aide-check-body .drug-list .drug-detail .drug-desc[data-v-8e8f3fba] {
          text-align: left;
          font-size: 12px;
          padding-left: 18px;
}
.aide-home .aide-home-drug .aide-check-body .drug-list .drug-detail .drug-desc .desc-title[data-v-8e8f3fba] {
            font-weight: 600;
            margin-bottom: 10px;
}
.aide-home .aide-home-drug .aide-check-body .drug-list .drug-detail .drug-desc .desc-text[data-v-8e8f3fba] {
            margin-bottom: 5px;
}
.aide-home .aide-home-drug .aide-check-body .drug-list .drug-detail .text-left[data-v-8e8f3fba] {
          text-align: right;
          height: 32px;
          line-height: 32px;
          font-weight: 600;
          padding-right: 16px;
}
.aide-home .aide-home-drug .aide-check-body .drug-list .drug-detail .text-right[data-v-8e8f3fba] {
          text-align: left;
          height: 32px;
          line-height: 32px;
          padding-left: 18px;
}
