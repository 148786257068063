[data-v-9fa485f6] .el-card__body {
  background-color: rgba(255, 255, 255, 0.9) !important;
}
.login-container[data-v-9fa485f6] {
  height: 100%;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
.login-page-footer[data-v-9fa485f6] {
    height: 40px !important;
}
.login-weaper .right-box[data-v-9fa485f6] {
    width: 480px !important;
}
.login-weaper .right-box .login-title[data-v-9fa485f6] {
      margin-top: 20px !important;
}
.login-weaper .right-box .login-main[data-v-9fa485f6] {
      padding: 0 45px !important;
}
}
.login-container[data-v-9fa485f6] {
  width: 100%;
  margin: 0 auto;
  background-color: #e7ecf9;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.login-container .top-box[data-v-9fa485f6] {
    height: 70px;
    background-color: #ffffff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.login-container .top-box .logo-box[data-v-9fa485f6] {
      margin-left: 13.85%;
      cursor: pointer;
}
.login-container .login-weaper[data-v-9fa485f6] {
    width: 100%;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    background-image: url("/img/login-bg.png");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 2%;
    position: relative;
}
.login-container .login-weaper .right-box[data-v-9fa485f6] {
      width: 580px;
      height: 90%;
      background: #ffffff;
      margin: 0 auto;
      border-radius: 10px;
      overflow: hidden;
      overflow-y: auto;
      position: absolute;
      right: 12vw;
      background-color: rgba(255, 255, 255, 0.9);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
}
.login-container .login-weaper .right-box .empty[data-v-9fa485f6] {
        height: 10%;
}
.login-container .login-weaper .right-box .login-title[data-v-9fa485f6] {
        margin-top: 90px;
        font-size: 24px;
        text-align: center;
        font-weight: 700;
}
.login-container .login-weaper .right-box .login-main[data-v-9fa485f6] {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1;
        padding: 0 90px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
}
.login-container .el-card.is-always-shadow[data-v-9fa485f6] {
    -webkit-box-shadow: none;
            box-shadow: none;
}
.login-container .login-page-footer[data-v-9fa485f6] {
    width: 100%;
    background-color: #f3f4f6;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.login-container .login-page-footer a[data-v-9fa485f6] {
      color: #3b94ff;
}
.login-container .login-page-footer a[data-v-9fa485f6]:hover {
        color: #f00;
        text-decoration: underline;
}
