.test-fail[data-v-a09c60be] {
  height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.test-fail .fail-title[data-v-a09c60be] {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}
.test-fail .cxjc[data-v-a09c60be] {
    cursor: pointer;
    width: 100px;
    height: 36px;
    line-height: 36px;
    background: #f4faff;
    border: 1px solid #3b94ff;
    border-radius: 5px;
    margin-left: 10px;
    font-size: 16px;
    color: #2d85ee;
    text-align: center;
}
