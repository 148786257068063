@-webkit-keyframes error403animation-data-v-1837a7ec {
0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
40% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}
45% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
}
50% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}
55% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
}
60% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}
100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
}
@keyframes error403animation-data-v-1837a7ec {
0% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
40% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}
45% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
}
50% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}
55% {
    -webkit-transform: rotateZ(-15deg);
            transform: rotateZ(-15deg);
}
60% {
    -webkit-transform: rotateZ(-20deg);
            transform: rotateZ(-20deg);
}
100% {
    -webkit-transform: rotateZ(0deg);
            transform: rotateZ(0deg);
}
}
.error403-body-con[data-v-1837a7ec] {
  width: 700px;
  height: 500px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.error403-body-con-title[data-v-1837a7ec] {
    text-align: center;
    font-size: 240px;
    font-weight: 700;
    color: #2d8cf0;
    height: 260px;
    line-height: 260px;
    margin-top: 40px;
}
.error403-body-con-title .error403-0-span[data-v-1837a7ec] {
      display: inline-block;
      position: relative;
      width: 170px;
      height: 170px;
      border-radius: 50%;
      border: 20px solid #ed3f14;
      color: #ed3f14;
      margin-right: 10px;
}
.error403-body-con-title .error403-0-span i[data-v-1837a7ec] {
        display: inline-block;
        font-size: 120px !important;
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
}
.error403-body-con-title .error403-key-span[data-v-1837a7ec] {
      display: inline-block;
      position: relative;
      width: 100px;
      height: 190px;
      border-radius: 50%;
      margin-right: 10px;
}
.error403-body-con-title .error403-key-span i[data-v-1837a7ec] {
        display: inline-block;
        font-size: 190px !important;
        position: absolute;
        left: 20px;
        -webkit-transform: translate(-50%, -60%);
                transform: translate(-50%, -60%);
        -webkit-transform-origin: center bottom;
                transform-origin: center bottom;
        -webkit-animation: error403animation-data-v-1837a7ec 2.8s ease 0s infinite;
                animation: error403animation-data-v-1837a7ec 2.8s ease 0s infinite;
}
.error403-body-con-message[data-v-1837a7ec] {
    display: block;
    text-align: center;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 4px;
    color: #dddde2;
}
.error403-btn-con[data-v-1837a7ec] {
  text-align: center;
  padding: 20px 0;
  margin-bottom: 40px;
}
